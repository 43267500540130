
import notificationBars from '@/notificationBars.json'

export default {
  data() {
    return {
      notification: {},
      notificationStorage: false,
      countdown_date : '2024-12-31T09:00:00.000Z',
    }
  },
  mounted() {
    // console.log("mounted" )
    setTimeout(() => this.getData(), 100)

  },
  methods: {
    async getData() {
      try {
        // Response Notification
        const response = await notificationBars
        this.notification = response.notification

        if(this.notification.countdown_timer){
        //  console.log("countdown_timer:true",this.notification.countdown_timer)
          this.countdown_date = new Date(this.notification.countdown_date).getTime();
          this.startCountDownTimer();
        }
        
        // Storage
        this.notificationStorage = sessionStorage.getItem('MYn')
        switch (this.notificationStorage) {
          case null:
            sessionStorage.setItem('MYn', 'false')
            this.notificationStorage = true
            break
          case 'false':
            this.notificationStorage = true
            break
          default:
            break
        }
      } catch (err) {
        return false
      }
    },
    close() {
      sessionStorage.setItem('MYn', 'true')
      this.notificationStorage = false
    },
    startCountDownTimer(){
      //console.log("countdown_date",this.countdown_date)
      const countdownFunction = () => {
                                const now = new Date().getTime();
                                const gap = this.countdown_date - now;

                                const second = 1000;
                                const minute = second * 60;
                                const hour = minute * 60;
                                const day = hour * 24;

                                const textDay = Math.floor(gap / day);
                                const textHour = Math.floor((gap % day) / hour);
                                const textMinute = Math.floor((gap % hour) / minute);
                                const textSecond = Math.floor((gap % minute) / second);

                                const daysElement = document.getElementById('days');
                                  if (daysElement) daysElement.innerText = textDay.toString().padStart(2, '0');

                                  const hoursElement = document.getElementById('hours');
                                  if (hoursElement) hoursElement.innerText = textHour.toString().padStart(2, '0');

                                  const minutesElement = document.getElementById('minutes');
                                  if (minutesElement) minutesElement.innerText = textMinute.toString().padStart(2, '0');

                                  const secondsElement = document.getElementById('seconds');
                                  if (secondsElement) secondsElement.innerText = textSecond.toString().padStart(2, '0');

                            };

                            setInterval(countdownFunction, 1000);
    }
  },
}
